import React from 'react';
import logo from './logo.svg';
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import StepperForm from './pages/form';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';


function App() {
  return (
    <div className="App d-flex">
      <div className="AppBg"></div>
      <BrowserRouter>
          <div className="form-container p-4">
            <Routes>
              <Route path="/" element={<StepperForm />} />
              <Route path="/:package_uid" element={<StepperForm />} />
              <Route path="/:package_uid/:stage" element={<StepperForm />} />
            </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
