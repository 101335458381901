import axios from "axios";
import { ExcelData, SessionData } from "./interfaces";

export default class API {
    debug = false;
    dateOptions: any = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    host = this.debug ? "http://127.0.0.1:8000" : "https://api.salambatv.com";
    socketHost: any = this.debug ? "ws://127.0.0.1:8000" : "wss://api.salambatv.com";
    apiVersion: string = "v1"; // should not end with foreward slash
    url: string = `${this.host}/${this.apiVersion}`; // should not end with foreward slash
    controller: AbortController;
    avatar: any;
    firstName: any;
    lastName: any;
    username: any;
    phoneNumber: any;
    email: any;
    country: any;
    axiosInstance = axios.create({
        baseURL: this.host,
        withCredentials: true,
    })
    cancelToken: any;

    headers: any = {
        "Content-Type": "application/json",
        "Accept": "application/json",
    }

    constructor(controller?: AbortController | null | undefined) {
        this.controller = controller ?? new AbortController();
        // this.headers['signal'] = this.controller.signal;
        // /////////////////////////////////////////////////// //
        this.avatar = sessionStorage.getItem('avatar');
        this.firstName = sessionStorage.getItem('first_name');
        this.lastName = sessionStorage.getItem('last_name');
        this.username = sessionStorage.getItem('username');
        this.country = sessionStorage.getItem('country');
        this.phoneNumber = sessionStorage.getItem('phone_number');
        this.email = sessionStorage.getItem('email');
        // /////////////////////////////////////////////////// //
        axios.defaults.withCredentials = true;
        this.axiosInstance.defaults.withCredentials = true;
        this.axiosInstance.defaults.signal = this.controller.signal;
        // this.cancelToken = axios.CancelToken.source();
        // this.axiosInstance.defaults.cancelToken = this.cancelToken;
    }

    async login(username: string, password: string) {
        let response: any = await this.axiosInstance.post(`/auth/login/`,
            {
                username: username,
                password: password
            },
            {
                headers: {
                    ...this.headers,
                }
            }).catch((error: any) => {
                return {
                    'success': false,
                    'message': error?.response?.data?.detail
                };
            })

        if (response?.data?.success) {
            const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
            const mode = darkThemeMq.matches ? "dark" : "light";
            this.setTheme(mode);
            return response.data;
        }
        return {
            'success': false,
            'message': response.message
        };
    }

    async getRefreshToken() {
        let response: any = await this.axiosInstance.post(`/auth/refresh/`, {},
            {
                headers: this.headers,
            })

        return response.data;
    }

    async logout() {
        let response: any = await this.axiosInstance.post(`/auth/logout/`, {},
            {
                headers: this.headers,
            }).then((value: any) => {
                sessionStorage.clear();
                localStorage.clear();
                return value;
            })

        return response.data;
    }

    async resetPassword(data: FormData) {
        let response = await axios.post(`${this.host}/auth/reset-password-email/`, data,
            {
                headers: this.headers,
            })

        return response.data;
    }

    async setPassword(data: FormData) {
        let response = await axios.post(`${this.host}/auth/set-password/`, data,
            {
                headers: this.headers,
            })

        return response.data;
    }

    async validatePasswordResetToken(data: FormData) {
        let response = await axios.post(`${this.host}/auth/validate-password-reset-token/`, data,
            {
                headers: this.headers,
            })

        return response.data;
    }

    async abortRequest(abort: boolean = false) {
        if(abort){
            this.controller.abort();
        }
    }

    async getPackages() {
        let response = await this.axiosInstance.get(`${this.url}/newsletter/packages/`,
            {
                headers: this.headers,
            })

        return response.data;
    }

    async getPackage(id:any) {
        let response = await this.axiosInstance.get(`${this.url}/newsletter/package/${id}/`,
            {
                headers: this.headers,
            })

        return response.data;
    }

    async sendPaymentRequest(data:FormData) {
        let response = await this.axiosInstance.post(`${this.url}/newsletter/pay/`, data,
            {
                headers: this.headers,
            })

        return response.data;
    }

    async getAnalytics() {
        let response = await this.axiosInstance.get(`${this.url}/analytics/`,
            { headers: this.headers },
        )

        return response.data;
    }

    async clientRegistration(data: FormData) {
        let response = await this.axiosInstance.post(`${this.url}/newsletter/client/`, data,
            {headers: this.headers}
        )

        return response.data;
    }

    async clientOtpConfirmation(data: FormData, uid: any) {
        let response = await this.axiosInstance.post(`${this.url}/newsletter/client/otp-confirmation/${uid}/`, data,
            {headers: this.headers}
        )

        return response.data;
    }

    async uploadAvatar(data: any) {
        let response = await this.axiosInstance.post(`${this.url}/user/update-avatar/`, data,
            { headers: this.headers },
        )

        return response.data;
    }

    async updateProfile(data: any) {
        let response = await this.axiosInstance.put(`${this.url}/user/update-profile/`, data,
            { headers: this.headers },
        )
        let sessionData: SessionData = {
            firstName: response?.data?.data?.first_name,
            lastName: response?.data?.data?.last_name,
            phone: response?.data?.data?.phone_number,
            username: response?.data?.data?.username,
            email: response?.data?.data?.email,
            store: response?.data?.data?.storeId,
            country: response?.data?.data?.country,
            storeName: response?.data?.data?.store_name,
            avatar: response?.data?.data?.avatar,
            lastSeen: response?.data?.data?.last_seen,
            createdAt: response?.data?.data?.created_at,
            updatedAt: response?.data?.data?.updated_at,
        }
        this.setSessionData(sessionData)
        return response.data;
    }

    async saveImage(data: any) {
        let response = await this.axiosInstance.post(`${this.url}/storage/save-image/`, data,
            { headers: this.headers },
        )

        return response.data;
    }

    async getImages() {
        let response = await this.axiosInstance.get(`${this.url}/storage/images/`,
            { headers: this.headers },
        )

        return response.data;
    }

    hexToRgb(hex: any) {
        var c: any;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length ===  3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',1)';
        }
        throw new Error('Bad Hex');
    }

    rgbaToHex(rgba: string) {
        const rgbaRegex = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*\d*\.\d+)?\)$/;

        const match = rgba.match(rgbaRegex);

        if (!match) {
            throw new Error('Invalid RGBA format');
        }

        const r = parseInt(match[1], 10);
        const g = parseInt(match[2], 10);
        const b = parseInt(match[3], 10);

        function componentToHex(c: any) {
            var hex = c.toString(16);
            return hex.length === 1 ? "0" + hex : hex;
        }

        if (isNaN(r) || isNaN(g) || isNaN(b) || r < 0 || r > 255 || g < 0 || g > 255 || b < 0 || b > 255) {
            throw new Error('Invalid RGB values');
        }

        var hexR = componentToHex(r);
        var hexG = componentToHex(g);
        var hexB = componentToHex(b);

        return "#" + hexR + hexG + hexB;
    }

    numberWithCommas(input: number) {
        return input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    parseDate(date_input: any) {
        let parsedDate: Date = new Date(date_input)
        let date = parsedDate.toLocaleDateString("en-US", this.dateOptions)
        return date;
    }

    capitalizeString(string: string) {
        return string.replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); });
    };

    setTheme(theme: string) {
        localStorage.setItem("themeMode", theme)
        return theme;
    };

    getTheme() {
        // System Theme Mode
        const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
        const mode = darkThemeMq.matches ? "dark" : "light"

        let theme = localStorage.getItem("themeMode")
        return theme || mode;
    };

    getSessionData() {
        this.avatar = sessionStorage.getItem('avatar');
        this.firstName = sessionStorage.getItem('first_name');
        this.lastName = sessionStorage.getItem('last_name');
        this.username = sessionStorage.getItem('username');
        this.country = sessionStorage.getItem('country');
        this.phoneNumber = sessionStorage.getItem('phone_number');
        this.email = sessionStorage.getItem('email');
        // ////////////////////////////////////////////////////////// //
        let data: Object = {
            avatar: sessionStorage.getItem('avatar'),
            firstName: sessionStorage.getItem('first_name'),
            lastName: sessionStorage.getItem('last_name'),
            username: sessionStorage.getItem('username'),
            country: sessionStorage.getItem('country'),
            phoneNumber: sessionStorage.getItem('phone_number'),
            email: sessionStorage.getItem('email'),
            storeId: sessionStorage.getItem('store'),
            storeName: sessionStorage.getItem('store_name'),
        }
        // ////////////////////////////////////////////////////////// //
        return data;
    };

    setSessionData(data: SessionData) {
        sessionStorage.setItem("first_name", data.firstName);
        sessionStorage.setItem("last_name", data.lastName);
        sessionStorage.setItem("phone_number", data.phone);
        sessionStorage.setItem("username", data.username);
        sessionStorage.setItem("email", data.email);
        sessionStorage.setItem("store", data.store);
        sessionStorage.setItem("country", data.country);
        sessionStorage.setItem("store_name", data.storeName);
        sessionStorage.setItem("avatar", `${this.host}${data.avatar}`);
        sessionStorage.setItem("last_seen", data.lastSeen);
        sessionStorage.setItem("created_at", data.createdAt);
        sessionStorage.setItem("updated_at", data.updatedAt);

        return true;
    };

    generateRandomNumber(maintainList: Array<number>, from: number = 0, to: number = 10) {
        let randomNumber: number = Math.floor(Math.random() * to)
        if (randomNumber < from) {
            this.generateRandomNumber(maintainList, from, to)
            return
        }
        if (maintainList.includes(randomNumber)) {
            this.generateRandomNumber(maintainList, from, to)
            return
        }
        return randomNumber;
    };

}
