import { Button } from "@mui/material";
import API from "../services/api.services";
import { useNavigate } from "react-router-dom";

const Package = ({ id, name, amount, details, onButtonClick = () => { }, onClick = () => { }, activeId, }: any) => {

    const navigate = useNavigate()

    const apiServices: API = new API();

    if (id == "71072962-dd7b-4200-be65-e16e960e4dce")
        return (
            <div className={`bg-light m-2 p-2 py-4 shadow rounded package-container ${activeId == id ? "active" : ""}`} style={{ width: '400px', height: '400px' }}>
                <h4>{name}</h4>
                <div onClick={(event: any) => { onClick(id) }} className="detail-section h-75 w-100 d-flex flex-column justify-content-center align-items-center">
                    <div className="table w-100 px-2 fs-6" style={{ textAlign: 'center' }}>
                        {
                            details?.map((detail: any, index: number) => {
                                return (
                                    <small className="" key={index}>
                                        <>{detail}</>
                                        <div className="my-1" style={{ backgroundColor: 'rgb(231, 231, 231)', height: '1px' }} />
                                    </small>
                                )
                            })
                        }
                    </div>

                </div>
                <div className="bottom-section h-25 w-100">
                    <h5>TSh {apiServices.numberWithCommas(amount)}/=</h5>
                    {/* <Button>Chagua</Button> */}
                </div>
            </div>
        );

    return (
        <div className={`bg-light m-2 p-3 py-4 shadow package-container rounded ${activeId == id ? "active" : ""}`} style={{ width: '350px', height: '350px' }}>
            <h5>{name}</h5>
            <div onClick={(event: any) => { onClick(id) }} className="detail-section h-75 w-100 d-flex flex-column justify-content-center align-items-center">
                <div className="table w-100 px-2 fs-6" style={{ textAlign: 'center' }}>
                    {
                        details?.map((detail: any, index: number) => {
                            return (
                                <small className="" key={index}>
                                    <>{detail}</>
                                    <div className="my-1" style={{ backgroundColor: 'rgb(231, 231, 231)', height: '1px' }} />
                                </small>
                            )
                        })
                    }
                </div>
            </div>
            <div className="bottom-section h-25 w-100">
                <h5 className="">TSh {apiServices.numberWithCommas(amount)}/=</h5>
                {/* <Button className="my-2" onClick={onButtonClick} >Chagua</Button> */}
            </div>
        </div>
    );
}

export default Package;