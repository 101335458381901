// StepperForm.js
import React, { useEffect, useRef, useState } from 'react';
import { Stepper, Step, StepLabel, Button, TextField, Typography, CircularProgress, FormControl, InputLabel, Input, Checkbox } from '@mui/material';
import API from '../services/api.services';
import Package from './package';
import { IMaskInput } from 'react-imask';
import { useParams, useNavigate } from 'react-router-dom'

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(
  function TextMaskCustom(props: any, ref: any) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="(#00) 000-0000-00"
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

const StepperForm = () => {

  const apiServices: API = new API();

  const navigate = useNavigate()
  const { package_uid, stage } = useParams()

  const [stages, setStages] = useState<any>([
    "packages",
    "contact",
    "confirmation",
    "payment",
    "finish",
  ]);
  const [activeStep, setActiveStep] = useState<any>(stage == null ? 0 : stages.indexOf(stage));
  const [activeId, setActiveId] = useState<any>("71072962-dd7b-4200-be65-e16e960e4dce")
  const codeOne = useRef<any>(null);
  const codeTwo = useRef<any>(null);
  const codeThree = useRef<any>(null);
  const codeFour = useRef<any>(null);
  const [packages, setPackages] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUserAgreed, setUserAgreed] = useState<boolean>(false);
  const [packageId, setPackageId] = useState<any>(null);
  const [confirmationData, setConfirmationData] = useState({
    package_id: null,
    phone: null,
    package_name: null,
    duration: null,
    odds: null,
    costs: "",
  })
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    code1: '',
    code2: '',
    code3: '',
    code4: '',
  });

  const [phoneNumber, setPhoneNumber] = React.useState<any>({
    textmask: '(255)',
    numberformat: '1320',
  });

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber({
      ...phoneNumber,
      [event.target.name]: event.target.value,
    });
  };

  const handleNext = async () => {
    setErrorMessage(null)
    setIsLoading(true)
    if (activeStep === 0) {
      navigate("contact")
    }
    if (activeStep === 1) {
      // Validate form data for Step 1 (Registration)
      if (!formData.firstName || !formData.lastName) {
        setErrorMessage('Jaza Sehemu Zote');
        setIsLoading(false);
        return;
      }

      if (phoneNumber.textmask.length < 17) {
        setErrorMessage('Namba Ya Simu Sio Sahihi');
        setIsLoading(false);
        return;
      }

      let phone: any = phoneNumber.textmask.replaceAll(" ", "")
      phone = phone.replaceAll("(", "")
      phone = phone.replaceAll(")", "")
      phone = phone.replaceAll("-", "")

      formData['phoneNumber'] = phone;
      setPhoneNumber(formData)

      let data: FormData = new FormData();
      data.append("first_name", formData.firstName);
      data.append("last_name", formData.lastName);
      data.append("phone_number", phone);

      let response: any = await apiServices.clientRegistration(data)
      if (!response?.success) {
        setErrorMessage(response?.message)
        setIsLoading(false);
        return;
      }
      sessionStorage.setItem("uid", response?.uid)
    }
    if (activeStep === 2) {
      // Validate form data for Step 1 (Registration)
      if (!formData.code1 || !formData.code2 || !formData.code3 || !formData.code4) {
        setErrorMessage('Please fill in all fields.');
        setIsLoading(false);
        return;
      }

      let data: FormData = new FormData();
      data.append("otp", `${formData.code1}${formData.code2}${formData.code3}${formData.code4}`);
      let uid: any = sessionStorage.getItem("uid")

      let response: any = await apiServices.clientOtpConfirmation(data, uid)
      if (!response?.success) {
        setErrorMessage(response?.message)
        return;
      }
      navigate(`/${package_uid}${stage == null ? "" : "/payment"}`)
      setActiveStep((prevActiveStep: any) => prevActiveStep + 1);
    }

    if (activeStep == 2) {
      let response: any = await apiServices.getPackage(package_uid)

      let _confirmationData = Object.assign(confirmationData)
      _confirmationData.package_id = package_uid
      _confirmationData.phone = formData.phoneNumber
      _confirmationData.package_name = response?.data?.name
      _confirmationData.duration = response?.data?.name?.split(" ")[response?.data?.name?.split(" ").length - 1]
      _confirmationData.odds = response?.data?.possible_count
      _confirmationData.costs = response?.data?.price
      setConfirmationData(confirmationData)
      setIsLoading(false)
      return
    }
    if (activeStep == 3) {
      let data: FormData = new FormData()
      let cuid = sessionStorage.getItem("uid") ?? ""
      data.append("cuid", cuid);
      data.append("puid", confirmationData?.package_id ?? "");
      let response: any = await apiServices.sendPaymentRequest(data)
      if (response?.success) {
        navigate(`/${package_uid}${stage == null ? "" : "/finish"}`)
        setIsLoading(false)
        setActiveStep((prevActiveStep: any) => prevActiveStep + 1);
        sessionStorage.clear()
        localStorage.clear()
        return
      }
      setErrorMessage(response?.message)
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
    setActiveStep((prevActiveStep: any) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setErrorMessage(null);
    setActiveStep((prevActiveStep: any) => prevActiveStep - 1);

    switch (activeStep) {

      case 2:
        navigate(`/${package_uid}/contact`)
        break;
    
      case 1:
        navigate(`/${package_uid}`)
        break;
    
      default:
        break;
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // Automatically move focus to the next field if the current one is filled
    if (value !== "") {
      if (name === 'code1') {
        codeTwo.current.querySelector("#code2").focus();
      } else if (name === 'code2') {
        codeThree.current.querySelector("#code3").focus();
      } else if (name === 'code3') {
        codeFour.current.querySelector("#code4").focus();
      }
    } else {
      if (name === 'code4') {
        codeThree.current.querySelector("#code3").focus();
      } else if (name === 'code3') {
        codeTwo.current.querySelector("#code2").focus();
      } else if (name === 'code2') {
        codeOne.current.querySelector("#code1").focus();
      }
    }
  };

  const sync = async () => {
    setIsLoading(true)
    let packages: any = await apiServices.getPackages()
    let list: any = [
      packages?.data[0],
      packages?.data[2],
      packages?.data[1],
    ];

    if (package_uid == null) {
      setActiveId("71072962-dd7b-4200-be65-e16e960e4dce")
      navigate(`/71072962-dd7b-4200-be65-e16e960e4dce${stage == null ? "" : "/contact"}`)
    } else {
      setActiveId(package_uid)
      navigate(`/${package_uid}${stage == null ? "" : "/contact"}`)
    }
    setPackages(list);
    setIsLoading(false)
  }

  useEffect(() => {
    sync()
  }, [])
  useEffect(() => {
  }, [packages])

  return (
    isLoading ? (<CircularProgress></CircularProgress>) : (
      <div>
        {
          errorMessage && <div className="alert alert-danger" role="alert">
            {errorMessage}
          </div>
        }
        <Typography className='mb-2' variant="h6">JAMVIKA NA SALAMBA !</Typography>
        <Stepper activeStep={activeStep} alternativeLabel>
          <Step>
            <StepLabel>Package</StepLabel>
          </Step>
          <Step>
            <StepLabel>Contact</StepLabel>
          </Step>
          <Step>
            <StepLabel>Confirmation</StepLabel>
          </Step>
          <Step>
            <StepLabel>Payment</StepLabel>
          </Step>
          <Step>
            <StepLabel>Finish</StepLabel>
          </Step>
        </Stepper>
        <div>
          {
            activeStep === 0 ? (
              <>
                <div className='d-flex justify-content-center align-items-center flex-wrap my-2'>
                  {
                    packages && packages?.map((pkg: any, index: number) => {
                      return (
                        <Package activeId={activeId}
                          onClick={(id: any) => {
                            setActiveId(id)
                            setPackageId(pkg?.id)
                            navigate(`/${id}`)
                            setConfirmationData((data: any) => {
                              data.package_id = id;
                              return data
                            })
                          }} key={index} id={pkg?.uid} name={pkg?.name} amount={pkg?.price} details={pkg?.details} />
                      )
                    })
                  }
                </div>
                <div className='d-flex justify-content-evenly'>
                  <Button disabled={activeStep === 0} onClick={handleBack}>
                    Rudi
                  </Button>
                  <Button disabled={activeId == null} variant="contained" color="primary" onClick={handleNext}>
                    Endelea
                  </Button>
                </div>
              </>
            )
              : activeStep === 1 ? (
                <div>
                  <form>
                    <TextField
                      className='my-2'
                      name="firstName"
                      label="Jina La Kwanza"
                      value={formData.firstName}
                      onChange={handleChange}
                      fullWidth
                      required
                    />
                    <TextField
                      className='my-2'
                      name="lastName"
                      label="Jina La Mwisho"
                      value={formData.lastName}
                      onChange={handleChange}
                      fullWidth
                      required
                    />
                    {/* <TextField
                      className='my-2'
                      name="phoneNumber"
                      label="Namba Ya Simu"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      fullWidth
                      required
                    /> */}
                    <FormControl className='my-2 w-100' variant="standard">
                      <InputLabel htmlFor="formatted-text-mask-input">Namba Ya Simu</InputLabel>
                      <Input
                        className='mx-2'
                        value={phoneNumber.textmask}
                        onChange={handlePhoneChange}
                        name="textmask"
                        id="formatted-text-mask-input"
                        inputComponent={TextMaskCustom as any}
                        required
                      />
                    </FormControl>
                  </form>
                  <div className="d-flex w-100 p-3 d-flex justify-content-start align-items-center">
                    <Checkbox checked={isUserAgreed} onChange={(event:any)=>{setUserAgreed(event.target.checked)}} />
                    <span style={{
                      textAlign: 'left'
                    }}>
                      Ninakubali na ninaafikiana na<a href="https://salambatv.com/privacy-policy/" className='mx-1'> Masharti na Vigezo </a>vinavyosimamia matumizi ya jukwaa hili.
                    </span>
                  </div>
                  <div className="d-flex justify-content-evenly">
                    <Button disabled={activeStep === 0} onClick={handleBack}>
                      Rudi
                    </Button>
                    <div className='mx-3'/>
                    <Button disabled={isUserAgreed == false} variant="contained" color="primary" onClick={handleNext}>
                      Endelea
                    </Button>
                  </div>
                </div>
              ) : activeStep === 2 ? (
                <div className='d-flex flex-column align-items-center my-3'>
                  <Typography variant="h6">Thibitisha Namba Ya Simu</Typography>
                  <div className='my-3' style={{ display: 'flex', gap: '1rem' }}>
                    <TextField
                      id="code1"
                      name="code1"
                      value={formData.code1}
                      onChange={handleChange}
                      style={{ width: '3rem', maxWidth: '3rem' }}
                      ref={codeOne}
                      fullWidth
                      required
                      inputProps={{ maxLength: 1, style: { textAlign: 'center' } }} // Limit input to 1 character
                    />
                    <TextField
                      id="code2"
                      name="code2"
                      value={formData.code2}
                      onChange={handleChange}
                      style={{ width: '3rem', maxWidth: '3rem' }}
                      ref={codeTwo}
                      fullWidth
                      required
                      inputProps={{ maxLength: 1, style: { textAlign: 'center' } }} // Limit input to 1 character
                    />
                    <TextField
                      id="code3"
                      name="code3"
                      value={formData.code3}
                      onChange={handleChange}
                      style={{ width: '3rem', maxWidth: '3rem' }}
                      ref={codeThree}
                      fullWidth
                      required
                      inputProps={{ maxLength: 1, style: { textAlign: 'center' } }} // Limit input to 1 character
                    />
                    <TextField
                      id="code4"
                      name="code4"
                      value={formData.code4}
                      onChange={handleChange}
                      style={{ width: '3rem', maxWidth: '3rem' }}
                      ref={codeFour}
                      fullWidth
                      required
                      inputProps={{ maxLength: 1, style: { textAlign: 'center' } }} // Limit input to 1 character
                    />
                  </div>
                  <div className="d-flex justify-content-evenly">
                    <Button disabled={activeStep === 0} onClick={handleBack}>
                      Rudi
                    </Button>
                    <div className='mx-3'/>
                    <Button variant="contained" color="primary" onClick={handleNext}>
                      Endelea
                    </Button>
                  </div>
                </div>
              ) : activeStep === 3 ? (
                <div className='d-flex flex-column align-items-center my-3'>
                  <Typography variant="h6">Uthibitisho Na Malipo</Typography>
                  <div className='d-flex justify-content-center align-items-center flex-wrap my-2 w-100'>
                    <table className='table table-striped mx-2'>
                      <thead></thead>
                      <tbody>
                        <tr>
                          <td style={{ textAlign: 'left' }}><small>Namba ya Simu</small></td>
                          <td style={{ textAlign: 'right' }}><small>{confirmationData.phone}</small></td>
                        </tr>
                        <tr>
                          <td style={{ textAlign: 'left' }}><small>Kifurushi</small></td>
                          <td style={{ textAlign: 'right' }}><small>{confirmationData?.package_name}</small></td>
                        </tr>
                        <tr>
                          <td style={{ textAlign: 'left' }}><small>Muda</small></td>
                          <td style={{ textAlign: 'right' }}><small>{confirmationData?.duration}</small></td>
                        </tr>
                        <tr>
                          <td style={{ textAlign: 'left' }}><small>Mechi/Jamvi</small></td>
                          <td style={{ textAlign: 'right' }}><small>{confirmationData?.odds}</small></td>
                        </tr>
                        <tr>
                          <td style={{ textAlign: 'left' }}><small>Gharama</small></td>
                          <td style={{ textAlign: 'right' }}><small>{apiServices.numberWithCommas(+confirmationData?.costs)}</small></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className='my-3 d-flex justify-content-evenly'>
                    <Button disabled={activeStep === 0} onClick={(event: any) => {
                      navigate("/")
                      navigate(0)
                    }}>
                      Cancel
                    </Button>
                    <div className='mx-3'/>
                    <Button variant="contained" color="primary" onClick={handleNext}>
                      Thibitisha
                    </Button>
                  </div>
                </div>
              ) : activeStep === 4 ? (
                <div className='d-flex flex-column align-items-center my-3'>
                  <Typography variant="h6">Hongera!</Typography>
                  <Typography>
                    <b>Umefanikiwa Kujisajili na Huduma Ya Jamvika na Salamba</b>
                  </Typography>
                  <p className='my-2' style={{ maxWidth: ' 430px', textAlign: 'left' }}>
                    <span>Utapata ujumbe wakuthibitisha malipo kupitia simu yako.</span><br/>
                    Kama haujapata ujumbe wa uthibitisho wa malipo, fuata hatua zifuatazo kulipia kifurushi.
                    <ol className='my-2'>
                      <li>Dial *150*50*1#</li>
                      <li>Ingiza lipa namba <b>61025128</b></li>
                      <li>Weka kiwango cha kifurushi unachohitaji kujiunga<br /><b>1000 (Wiki), 5000 (Wiki), 15,000 (Mwezi)</b></li>
                      <li>Chagua 1 ku thibitisha, malipo kwenda <b>Salamba Tv</b></li>
                      <li>Weka nambari yako ya siri <b>••••</b></li>
                    </ol>
                    <span>Utapokea ujumbe wa uthibitisho kutoka Kwetu.</span>
                  </p>
                  <div className='my-3'>
                    <Button href='https://salambatv.com' variant="contained" color="primary">
                      Maliza
                    </Button>
                  </div>
                </div>
              ) : null
          }
        </div>
      </div>
    )
  );
};

export default StepperForm;
